import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home";

Vue.use(Router);

const routes = [
    {
        path: "*",
        redirect: "/"
    },
    {
        path: "/",
        name: "home",
        component: Home
    }
];

export default new Router({
    mode: "history",
    routes
});
